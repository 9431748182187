import styled from 'styled-components';

export const StyledTable = styled.table`
	border-collapse: collapse;
	border-color: ${(props) => props.theme.borderColor};
	caption {
		text-align: left;
	}
	tr:nth-of-type(odd) {
		background-color: #f8f8f8;
	}
	td:nth-child(3) {
		text-align: right;
	}

	td,
	th {
		border-spacing: 0;
		border-style: solid;
		border-width: 1px;
		padding: 8px;
		text-align: left;
	}
`;
