import React from 'react';
import { MessageDiv, StyledArticle } from '../../styles';

import { Layout } from '../../components/Layout';
import { StyledH2, CustomList } from '../../styles/common';
import { StyledTable } from '../../styles/diagnostyka';
import { SEO } from '../../components/SEO';

export function Head() {
	return <SEO title="Diagnostyka" description="Diagnostyka." />;
}

function DiagnostykaPage() {
	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					Diagnostyka
				</StyledH2>
				<MessageDiv>
					<h2>Okręgowa Stacja Kontroli Pojazdów</h2>
					<p>
						Oferujemy usługi zwizane z badaniami technicznymi wszystkich
						pojazdów.
					</p>
					<p>
						Godziny pracy:
						<br />
						Pon. - Pt. od 6<sup>00</sup> do 14<sup>00</sup>, 22<sup>00</sup> do
						6<sup>00</sup>
						<br />
						Sob. 6<sup>00</sup> do 13<sup>00</sup>
						<br />
						Niedz. 22<sup>00</sup> do 6<sup>00</sup>
					</p>

					<StyledTable>
						<caption>
							<p>
								Tabela opłat za badania techniczne pojazdów przeprowadzane w
								stacjach kontroli Pojazdów.
								<br />
								(Na podstawie załącznika do rozporzdzenia Ministra
								Infrastruktury z dn. 29 września 2004. Dz.U. 223 poz.2261)
							</p>
						</caption>
						<thead>
							<tr>
								<th>Lp.</th>
								<th className="col2">Wyszczególnienie</th>
								<th>Cena&nbsp;brutto</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td colSpan="2">Okresowe badania techniczne:</td>
							</tr>
							<tr>
								<td>1.1</td>
								<td className="col2">motocykl, ciągnik rolniczy</td>
								<td>62,00</td>
							</tr>
							<tr>
								<td>1.2</td>
								<td>
									samochód osobowy, autobus przeznaczony do przewozu nie więcej
									niż 15 osób łącznie z kierowcą, samochód ciężarowy i specjalny
									do 3,5 t d.m.c., pojazd trójkołowy powyżej 0,4 t m.w.{' '}
									<sup>1)</sup>
								</td>
								<td>98,00</td>
							</tr>
							<tr>
								<td>1.3</td>
								<td>
									samochód ciężarowy i specjalny, ciągnik samochodowy siodłowy
									powyżej 3,5t do 16t d.m.c.&nbsp;
								</td>
								<td>153,00</td>
							</tr>
							<tr>
								<td>1.4</td>
								<td>
									samochód ciężarowy i specjalny, ciągnik samochodowy siodłowy
									powyżej 16t d.m.c., ciągnik samochodowy balastowy&nbsp;
								</td>
								<td>176,00</td>
							</tr>
							<tr>
								<td>1.5</td>
								<td>
									autobus przeznaczony do przewozu więcej niż 15 osób łącznie z
									kierowcą&nbsp;
								</td>
								<td>199,00</td>
							</tr>
							<tr>
								<td>1.6</td>
								<td>przyczepa ciężarowa rolnicza do 1,5t d.m.c.&nbsp;</td>
								<td>40,00</td>
							</tr>
							<tr>
								<td>1.7</td>
								<td>
									przyczepa ciężarowa rolnicza powyżej 1,5t do 6t d.m.c.&nbsp;
								</td>
								<td>50,00</td>
							</tr>
							<tr>
								<td>1.8</td>
								<td>przyczepa ciężarowa rolnicza powyżej 6t d.m.c.&nbsp;</td>
								<td>70,00</td>
							</tr>
							<tr>
								<td>1.9</td>
								<td>
									przyczepa (naczepa) ciężarowa i specjalna do 3,5 t d.m.c.
								</td>
								<td>78,00</td>
							</tr>
							<tr>
								<td>1.10</td>
								<td>
									przyczepa (naczepa) ciężarowa i specjalna powyżej 3,5t do 16t
									d.m.c.&nbsp;
								</td>
								<td>163,00</td>
							</tr>
							<tr>
								<td>1.11</td>
								<td>
									przyczepa (naczepa) ciężarowa i specjalna powyżej 16t
									d.m.c.&nbsp;
								</td>
								<td>177,00</td>
							</tr>
							<tr>
								<td>1.15</td>
								<td>
									pojazd przystosowany do zasilania gazem (za badanie
									specjalistyczne)
								</td>
								<td>63,00</td>
							</tr>
							<tr>
								<td>1.17</td>
								<td>tramwaj - wagon silnikowy</td>
								<td>330,00</td>
							</tr>
							<tr>
								<td>1.18</td>
								<td>tramwaj - wagon przegubowy</td>
								<td>395,00</td>
							</tr>
							<tr>
								<td>1.19</td>
								<td>tramwaj - wagon doczepny czynny</td>
								<td>250,00</td>
							</tr>
							<tr>
								<td>1.20</td>
								<td>tramwaj - wagon doczepny bierny</td>
								<td>110,00</td>
							</tr>
							<tr>
								<td>1.21</td>
								<td>
									trolejbus - badania elektryczne <sup>2)</sup>
								</td>
								<td>115,00</td>
							</tr>
							<tr>
								<td>2</td>
								<td colSpan="2">
									Ponowne sprawdzenie mechanizmów i zespołów w pojeździe, w
									którym stwierdzono usterki w trakcie badań technicznych w
									zakresie:
								</td>
							</tr>
							<tr>
								<td>2.1</td>
								<td>skuteczności i równomierności działania hamulców</td>
								<td>20,00</td>
							</tr>
							<tr>
								<td>2.2</td>
								<td>skuteczności działania hamulców tramwaju</td>
								<td>120,00</td>
							</tr>
							<tr>
								<td>2.3</td>
								<td>
									skuteczność działania hamulca eletrodynamicznego trolejbusu
								</td>
								<td>50,00</td>
							</tr>
							<tr>
								<td>2.4</td>
								<td>
									ustawienia i natężenia świateł drogowych i świateł
									mijania&nbsp;
								</td>
								<td>14,00</td>
							</tr>
							<tr>
								<td>2.5</td>
								<td>
									połączeń układu kierowniczego oraz jałowego ruchu koła
									kierownicy, luzów w zawieszeniu&nbsp;
								</td>
								<td>20,00</td>
							</tr>
							<tr>
								<td>2.6</td>
								<td>toksyczności spalin</td>
								<td>14,00</td>
							</tr>
							<tr>
								<td>2.7</td>
								<td>poziomu hałasu&nbsp;</td>
								<td>20,00</td>
							</tr>
							<tr>
								<td>2.8</td>
								<td>geometrii kół jednej osi&nbsp;</td>
								<td>36,00</td>
							</tr>
							<tr>
								<td>2.9</td>
								<td>działania amortyzatorów jednej osi</td>
								<td>14,00</td>
							</tr>
							<tr>
								<td>2.10</td>
								<td>wszystkich innych usterek łącznie&nbsp;</td>
								<td>20,00</td>
							</tr>

							<tr>
								<td>4</td>
								<td colSpan="2">
									Dodatkowe badanie techniczne pojazdu skierowanego przez organ
									kontroli ruchu drogowego w razie uzasadnionego przypuszczenia,
									że zagraża bezpieczeństwu ruchu drogowego lub narusza
									wymagania ochrony środowiska:
								</td>
							</tr>
							<tr>
								<td>4.1</td>
								<td>
									określone usterki techniczne, warunki techniczne (osobno za
									każde określone warunki techniczne lub badany układ czy zespół
									pojazdu) <sup>4)</sup>
								</td>
								<td>20,00</td>
							</tr>
							<tr>
								<td>4.2</td>
								<td>
									po wypadku, kolizji drogowej (za badanie specjalistyczne)
								</td>
								<td>94,00</td>
							</tr>
							<tr>
								<td>4.3</td>
								<td>dane techniczne niezgodne ze stanem faktycznym</td>
								<td>51,00</td>
							</tr>
							<tr>
								<td>5</td>
								<td colSpan="2">
									Dodatkowe badanie techniczne pojazdu skierowanego przez
									kierownika rejonowego urzędu rządowej administracji ogólnej w
									razie uzasadnionego przypuszczenia, że zagraża bezpieczeństwu
									ruchu drogowego lub naruszawymagania ochrony środowiska:
								</td>
							</tr>
							<tr>
								<td>5.1</td>
								<td>
									co do którego zachodzi podejrzenie, że nie spełnia określonych
									warunków technicznych <sup>3)</sup>
								</td>
								<td>20,00</td>
							</tr>
							<tr>
								<td>5.2</td>
								<td>
									po wypadku, kolizji drogowej (za badanie specjalistyczne)
								</td>
								<td>94,00</td>
							</tr>
							<tr>
								<td>6</td>
								<td colSpan="2">
									Pozostałe dodatkowe badania techniczne pojazdu:
								</td>
							</tr>

							<tr>
								<td>6.2</td>
								<td>
									który ma być używany jako taksówka osobowa lub bagażowa (za
									warunki dodatkowe)
								</td>
								<td>42,00</td>
							</tr>
							<tr>
								<td>6.3</td>
								<td>
									który ma być używany jako uprzywilejowany (za warunki
									dodatkowe)
								</td>
								<td>50,00</td>
							</tr>
							<tr>
								<td>6.4</td>
								<td>
									który ma być użwany do przewozu materiałów niebezpiecznych (za
									badanie specjalistyczne)
								</td>
								<td>85,00</td>
							</tr>
							<tr>
								<td>6.5</td>
								<td>
									autobusu, który może poruszać się po drogach z prędkością 100
									km/h (za badanie specjalistyczne)
								</td>
								<td>126,00</td>
							</tr>
							<tr>
								<td>6.6</td>
								<td>
									do nauki jazdy lub przeprowadzania egzaminu państwowego (za
									warunki dodatkowe)
								</td>
								<td>48,00</td>
							</tr>
							<tr>
								<td>6.7</td>
								<td>
									w którym dokonano zmian konstrukcyjnych lub wymiany elementów
									powodujcych zmianę danych w dowodzie rejestracyjnym
								</td>
								<td>82,00</td>
							</tr>
							<tr>
								<td>6.8</td>
								<td>
									o którym mowa w art. 81 ust.8 pkt 4 ustawy z dnia 20 czerwca
									1997 r. - Prawo o ruchu drogowym
								</td>
								<td>50,00</td>
							</tr>
							<tr>
								<td>6.9</td>
								<td>
									skierowany przez starostę lub na wniosek posiadacza pojazdu:
								</td>
								<td />
							</tr>
							<tr>
								<td />
								<td>
									<ul>
										<li>
											w celu identyfikacji lub ustalenia danych niezbędnych do
											jego rejestracji
										</li>
									</ul>
								</td>
								<td>60,00</td>
							</tr>
							<tr>
								<td />
								<td>
									<ul>
										<li>
											jeżeli z dokumentów wymaganych do jego rejestracji wynika,
											że uczestniczył on w wypadku drogowym, lub narusza
											wymagania ochrony środowiska
										</li>
									</ul>
								</td>
								<td>94,00</td>
							</tr>
							<tr>
								<td>6.10</td>
								<td>
									pojazd sprowadzony z zagranicy i noszcy ślady uszkodzeń lub
									którego stan techniczny wskazuje na naruszenie elementów
									nośnych konstrukcji pojazdu, mogących stwarzać zagrożenie dla
									bezpieczeństwa ruchu drogowego
								</td>
								<td>94,00</td>
							</tr>
							<tr>
								<td>7</td>
								<td colSpan="2">
									Sprawdzenie spełniania dodatkowych warunków technicznych dla
									niektórych pojazdów oraz pozostałe czynności przeprowadzane w
									stacjach kontroli pojazdów:
								</td>
							</tr>
							<tr>
								<td>7.1</td>
								<td>
									prawidłowości przystosowania pojazdu silnikowego do ciągnięcia
									przyczepy 1)
								</td>
								<td>35,00</td>
							</tr>
							<tr>
								<td>7.2</td>
								<td>
									czy pojazd odpowiada dodatkowym warunkom technicznym dla
									pojazdów przeznaczonych do wykonywania czynności na drodze{' '}
									<sup>4)</sup>
								</td>
								<td>21,00</td>
							</tr>
							<tr>
								<td>7.3</td>
								<td>
									czy pojazd odpowiada dodatkowym warunkom technicznym wymaganym
									dla pojazdów ciężarowych przystosowanych do przewozu osób{' '}
									<sup>4)</sup>
								</td>
								<td>48,00</td>
							</tr>
							<tr>
								<td>7.4</td>
								<td>
									czy pojazd odpowiada dodatkowym warunkom technicznym wymaganym
									dla autobusu miejskiego używanego w komunikacji miejskiej lub
									miejskiej i podmiejskiej <sup>4)</sup>
								</td>
								<td>41,00</td>
							</tr>
							<tr>
								<td>7.5</td>
								<td>
									czy pojazd odpowiada dodatkowym warunkom technicznym wymaganym
									dla autobusu szkolnego <sup>4)</sup>
								</td>
								<td>41,00</td>
							</tr>
							<tr>
								<td>7.6</td>
								<td>wykonanie numeru nadwozia</td>
								<td>49,00</td>
							</tr>
							<tr>
								<td>7.7</td>
								<td>wykonanie numeru silnika</td>
								<td>49,00</td>
							</tr>
							<tr>
								<td>7.8</td>
								<td>wykonanie tabliczki i jej umieszczenie</td>
								<td>36,00</td>
							</tr>
							<tr>
								<td>7.9</td>
								<td>
									sporzdzenie opinii (na wniosek właściciela pojazdu) do wniosku
									o odstępstwo od warunków technicznych
								</td>
								<td>82,00</td>
							</tr>
							<tr>
								<td>7.10</td>
								<td>
									pomiar prawidłowości rozmieszczenia punktów bazowych nadwozia
									samonośnego pojazdów samochodowych do 3,5 t d.m.c., zwizanych
									z zespołami i układami bezpieczeństwa jazdy <sup>5)</sup>
								</td>
								<td>255,00</td>
							</tr>
						</tbody>
					</StyledTable>
					<br />
					<p>Objaśnienia:</p>
					<ul style={{ listStyle: 'none' }}>
						<li>
							1) W przypadku pojazdu przedstawionego do okresowego badania
							technicznego z adnotacją w dowodzie rejestracyjnym o treści
							&quot;przystosowany do cignięcia przyczepy&quot; lub
							&quot;HAK&quot; nie pobiera się opłaty określonej w lp 7.1 tabeli.
						</li>
						<li>2) Pozostałe badania jak dla autobusu.</li>
						<li>
							3) W przypadku gdy opłata za badanie techniczne pojazdu powstała z
							połączenia opłat określonych w lp. 4.1 i 5.1 tabeli byłaby większa
							od opłaty określonej w lp. 1 tabeli, pobiera się opłatę określoną
							w lp.1 tabeli.
						</li>
						<li>
							4) Opłata obowizuje również przy okresowym badaniu technicznym.
						</li>
						<li>
							5) Opłatę mogą stosować tylko stacje kontrCustomListi pojazdów
							wyposażone w przyrząd do pomiarów i oceny prawidłowości
							rozmieszczenia punktów bazowych nadwozia samonośnego pojazdów
							samochodowych do 3,5 t d.m.c., zwizanych z zespołami i układami
							bezpieczeństwa jazdy.
						</li>
					</ul>

					<p>Uwaga! Użyte w tekście skróty oznaczają:</p>
					<ul>
						<li>m.w. - masa własna pojazdu,</li>
						<li>d.m.c. - dopuszczalna masa całkowita pojazdu.</li>
					</ul>

					<div>
						Mimo dołożenia wszelkich starań nie gwarantujemy, ze publikowane
						dane nie zawierają uchybień lub błedów, które nie mogą jednak być
						podstawą do roszczeń.
					</div>
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
}

export default DiagnostykaPage;
